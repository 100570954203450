// Common && Variables
@import '@styles/app';
@import '../../styles/variables';
@import '@andes/technical-error/index';
@import '@andes/badge/index';
@import '@andes/button/base';
@import '@andes/button/lib/styles/modifier/transparent';
@import '@andes/snackbar/index';
@import '@andes/thumbnail/base';
@import '@andes/thumbnail/lib/styles/modifier/circle';
@import '@andes/typography/index';

html,
body {
  background-color: $andes-bg-primary;
}

.server-error,
.ui-empty-state {
  height: 100%;
  margin-top: $andes-spacing-32;

  &__title {
    font-weight: $font-weight-regular;
    margin-bottom: 0;
  }

  &__description {
    font-weight: $font-weight-light;
  }

  &__link,
  a:visited,
  a:hover {
    font-size: $font-size-16;
    font-weight: $font-weight-light;
    width: auto;
    text-decoration: none;
    color: $andes-blue-500;
  }
}
