// Variables
@import './common';
@import './variables';
@import './mixins';

// Andes components
@import '@andes/button/index';
@import '@andes/card/index';
@import '@andes/dropdown/index';
@import '@andes/form/index';
@import '@andes/list/index';
@import '@andes/message/index';
@import '@andes/textfield/index';
@import '@andes/technical-error/index';
@import '@andes/badge/index';
@import '@andes/button/index';
@import '@andes/button/lib/styles/modifier/transparent';
@import '@andes/snackbar/index';
@import '@andes/thumbnail/index';
@import '@andes/thumbnail/lib/styles/modifier/circle';
@import '@andes/typography/index';

// Overrides
@import './@overrides/message';

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  height: inherit;

  @media (min-width: $large-tablet) {
    .nav-header {
      min-height: 56px;
    }
  }
}
